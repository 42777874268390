
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class WorkshopFormData extends Vue {
    @Prop() responses!: any[];
    @Prop() otherResponses!: any[] | null;
    @Prop() otherFormText!: string;
    @Prop() label: string | undefined;

    type = 'list'
    series = [] as any[];
    chartOptions = {
        chart: {
            type: 'bar',
            height: 350,
        },
        plotOptions: {
            bar: {
                columnWidth: '55%',
                endingShape: 'rounded',
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val: any, options: any) {
                return val + "%";
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: [] as any[],
        },
        yaxis: {
            title: {
                text: '#'
            }
        },
        fill: {
            opacity: 1
        },
    }

    mounted() {
        if (this.responses.length < 5) {
            this.type = 'chart'
        } else {
            this.type = 'list'
        }
    }

    @Watch('type')
    onTypeChange() {
        let categories = new Set();
        this.responses.forEach(e => categories.add(e.value))
        if (this.otherResponses) {
            this.otherResponses.forEach(e => categories.add(e.value))
        }
        if (this.type === 'chart') {
            this.series = [
                {
                    // total responses
                    name: `${this.label || '#'}`,
                    data: this.responses.map(e => e.count).map(a => {
                        // calculate the percentage of the total responses (this.responses.map(e => e.count)
                        return Math.round(a / this.responses.reduce((a, b) => a + b.count, 0) * 100)
                    })
                },
                {
                    name: `${this.otherFormText || 'Other'}`,
                    data: this.otherResponses ? this.otherResponses.map(e => e.count).map(a => {
                        // calculate the percentage of the total responses (this.responses.map(e => e.count)
                        return Math.round(a / this.responses.reduce((a, b) => a + b.count, 0) * 100)
                    }) : []
                }
            ]
            this.chartOptions = {
                ...this.chartOptions,
                xaxis: {
                    categories: Array.from(categories),
                },
                yaxis: {
                    title: {
                        text: '#'
                    }
                },
            }
        }
    }
}

